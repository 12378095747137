import React,{useEffect, useState} from "react"
import styles from "./../../scss/components/EventosLollaMute/_SpecialEvent.module.scss"
import BackgroundImage from "./BackgroundImage"
import VolverButton from "./VolverButton"
import Titles from "./Titles"
import EventosCards from "./EventosCards"
import { navigate } from "gatsby"
import StepForm from "../TheBirraBehind/StepForm"

function SpecialEvent({ venue, data,location}) {
  const [isForm, setIsForm] = useState(false)
  const [selectedEvent,setSelectedEvent] = useState({})
  const [step,setStep] = useState("")

  const { name } = venue
  const isLolla = name == "lolla"
  // console.log("Data en SpecialEvent:", JSON.stringify(data, null, 2))

  const handlerEvent = (event)=>{
    if(event.id === "lolla" || event.id === "mute") navigate('/lamusicaentusmanos/'+event.id)
    else{
    setSelectedEvent(event)
    setIsForm(true)
  }
  }
  const handleFormBack = ()=>{
    setIsForm(false)
  }

  useEffect(() => {
    // window.scrollTo(0, 0)
    //smooth scroll
    document.getElementById("top").scrollIntoView({ behavior: "smooth" })
  }, [selectedEvent])

  return (
    <div className={isLolla ? styles.containerLolla : styles.container} id="top">
      <div className={styles.firstWrapper}>
        <BackgroundImage isLolla={isLolla} />
        <VolverButton type="top" />
        <Titles venue={venue} />
        <EventosCards venue={venue} data={data} setSelectedEvent ={handlerEvent}/>
        <VolverButton type="bottom" />
        <div className={styles.marginBottom} id="bottom">
          <br></br>
        </div>
      </div>
      {isForm ? (
                  <StepForm
                    selectedEvent={selectedEvent}
                    selectedProvince={selectedEvent.province}
                    setStep={setStep}
                    onBack={handleFormBack}
                    location={location}
                  />
                ) : null}
    </div>
  )
}

export default SpecialEvent
