import React from "react"
import styles from "./../../scss/components/EventosLollaMute/_EventoCard.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"

function CardImage({ evento }) {
  // console.log("data each venue on CardImage via props:", JSON.stringify(evento))
  // console.log("evento url: ", evento.url)

  const imageData = {
    layout: "constrained",
    // placeholder: { fallback: data.placeholderImage.childImageSharp.gatsbyImageData },
    images: {
      fallback: {
        src: evento.url ? evento.url : evento.iconUrl, // Adjust this to your actual data structure
        srcSet: evento.url,
        sizes: "100vw",
      },
    },
  }

  return (
    <div>
      <GatsbyImage
        image={imageData}
        className={styles.eventImg}
        alt="evento image"
      />
    </div>
  )
}

export default CardImage
