import React from "react"
import styles from "./../../scss/components/EventosLollaMute/_EventosCards.module.scss"
import EventoLollaMute from "./EventoCard"

function EventosCards({ venue, data, setSelectedEvent }) {
  // console.log("Data EventosCards:", JSON.stringify(data, null, 2))
  const { name } = venue
  // console.log("venue name", name)

  const isLolla = name == "lolla"
  const lollaEvents = isLolla && data?.allLollaEvents?.edges
  // console.log("lollaEvents", lollaEvents)

  const isMute = name == "mute"
  const muteEvents = isMute && data?.allMuteEvents?.edges
  // console.log("muteEvents", muteEvents)
  
  const areEvents = name === "events"
  // Sort the events array by index
  function compareNodeEventsByIndex(a, b) {
    return a.node.index - b.node.index
  }
  lollaEvents && lollaEvents.sort(compareNodeEventsByIndex)
  // console.log("lollaEvents sorted", lollaEvents)
  muteEvents && muteEvents.sort(compareNodeEventsByIndex)
  // console.log("muteEvents sorted", muteEvents)

  return (
    <div className={styles.cardsContainer}>
      {isLolla && (
        <>
          {lollaEvents.map(event => {
            {
              if (event.node.visible)
                return (
                  <EventoLollaMute
                    key={event.node.id}
                    evento={event.node}
                    venueName={name}
                    setSelectedEvent={setSelectedEvent}
                  />
                )
            }
          })}
        </>
      )}
      {isMute && (
        <>
          {muteEvents.map(event => {
            {
              if (event.node.visible)
                return (
                  <EventoLollaMute
                    key={event.node.id}
                    evento={event.node}
                    venueName={name}
                    setSelectedEvent={setSelectedEvent}
                  />
                )
            }
          })}
        </>
      )}
      {areEvents && (
 <>
 {data.map(event => {
   {
     if (event.node.visible)
       return (
         <EventoLollaMute
           key={event.node.id}
           evento={event.node}
           venueName={name}
           setSelectedEvent={setSelectedEvent}
         />
       )
   }
 })}
</>
      )}
    </div>
  )
}

export default EventosCards
