import React, { useState } from "react"
import styles from "./../../scss/components/EventosLollaMute/_EventoCard.module.scss"
import { Link } from "gatsby"
import { transformToSingleWord } from "../../utils/functions"
import CardImage from "./CardImage"

function EventoLollaMute({ evento, venueName,setSelectedEvent,eventType}) {
  // const { photo, date, time, artist, location } = evento
  let { url, schedule, name, address, id } = evento
  if(id==="lolla") name = "Lollapalooza"
  if(id==="mute") name = "Mute"
  const transformedEventName = transformToSingleWord(name ? name : id)

  return (
    <div className={`${styles.cardContainer}`}>
      <CardImage evento={evento} />
      <div className={styles.bottomContainer}>
        <div className={styles.infoContainer}>
          <p className={styles.eventDate}>{schedule}</p>
          <h1 className={styles.eventArtist}>{name}</h1>
          <p className={styles.eventLocation}>{address}</p>
        </div>
        <div className={styles.button} onClick={()=>{
          setSelectedEvent && setSelectedEvent(evento)
        }}>
          {eventType === "lolla" || eventType === "mute" ?  <Link
            to={`/lamusicaentusmanos/${venueName}/${transformedEventName}`}
            id={`/lamusicaentusmanos/${venueName}/${transformedEventName}`}
            className={styles.HeaderLink}
            state={{ scrollToTop: true }}
            onClick={() => {
              window.dataLayer.push({
                event: "GAEvent",
                event_category: "menu",
                event_action: "Click",
                event_label: `https://www.budweiser.com.ar/lamusicaentusmanos/${transformedEventName}`,
                interaction: true,
                component_name: "",
                element_text: "",
              })
            }}
          >
            <h2 className={styles.btnText}>participá por entradas</h2>
          </Link>: 
            <h2 className={styles.btnText}>participá por entradas</h2>
          }
        </div>
      </div>
    </div>
  )
}

export default EventoLollaMute
