import React from "react"
import styles from "./../../scss/components/EventosLollaMute/_Titles.module.scss"
import arrowDown from "../../images/icons/arrowDown.png"
import { Col, Row } from "react-bootstrap"

function Titles({ venue }) {
  const { name, title, desktopTitleOne, desktopTitleTwo } = venue
  return (
    <div className={styles.titlesContainer}>
      {(name == "mute" || name === 'events') &&(
        <div className={styles.titlesContainerMute}>
          <div className={styles.textContainerMute}>
            <h1 className={`${styles.title} ${styles.mobileTitle}`}>{title}</h1>
            <h1 className={`${styles.title} ${styles.desktopTitleMute}`}>
              {desktopTitleOne} <br />
              {desktopTitleTwo}
            </h1>
            <h1 className={styles.subtitle}>participá por entradas</h1>
          </div>
        </div>
      )}

      {name == "lolla" && (
        <div className={styles.titlesContainerLolla}>
          <div className={styles.textContainerLolla}>
            <h1 className={`${styles.title}`}>{title}</h1>
            <h1 className={styles.subtitle}>participá por entradas</h1>
          </div>
        </div>
      )}

   

      <Row className="d-flex justify-content-center m-0">
        <Col xs={"auto"}>
          <a
            onClick={e =>
              document
                .querySelector("#bottom")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            <img src={arrowDown} className={styles.ArrowDownSection} />
          </a>
        </Col>
      </Row>
    </div>
  )
}

export default Titles
