import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./../../scss/components/EventosLollaMute/_BackgroundImage.module.scss"
import {IsMobileDevice} from '../../utils/index';

function BackgroundImage({ isLolla }) {
  const [isMobile, setIsMobile] = React.useState(false)

    React.useEffect(() => {
        setIsMobile(IsMobileDevice())
    }, [])

  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(base: { eq: "background.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lollaBackgroundImage: file(base: { eq: "lolla_background30_8.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lollaBackgroundImageMobile: file(base: { eq: "background.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allFile(
        filter: { relativeDirectory: { eq: "../../images/EventosLollaMute" } }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={
        isLolla
          ? isMobile ? data.lollaBackgroundImageMobile.childImageSharp.fluid : data.lollaBackgroundImage.childImageSharp.fluid
          : data.backgroundImage.childImageSharp.fluid
      }
      loading="eager"
      className={isLolla ? styles.backgroundImgLolla : styles.backgroundImg}
    />
  )
}

export default BackgroundImage
