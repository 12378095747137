import React from "react"
import { Link } from "gatsby"
import styles from "./../../scss/components/EventosLollaMute/_VolverButton.module.scss"
import ArrowLeft from "./../../images/EventosLollaMute/arrow_left.svg"

function VolverButton({ type }) {
  const isTopBtn = type == "top"

  return (
    <div
      className={
        isTopBtn ? styles.volverContainer : styles.volverContainerBottom
      }
    >
      <img
        className={isTopBtn ? styles.arrowLeft : styles.arrowLeftBottom}
        src={ArrowLeft}
      ></img>

      <Link
        to={`/lamusicaentusmanos/`}
        id={`/lamusicaentusmanos/`}
        className={styles.HeaderLink}
        onClick={() => {
          window.dataLayer.push({
            event: "GAEvent",
            event_category: "menu",
            event_action: "Click",
            event_label: `https://www.budweiser.com.ar/lamusicaentusmanos/`,
            interaction: true,
            component_name: "",
            element_text: "",
          })
        }}
      >
        <p className={isTopBtn ? styles.volverText : styles.volverTextBottom}>
          volver
        </p>
      </Link>
    </div>
  )
}

export default VolverButton
